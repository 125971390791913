import React, { Component } from "react";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../assets/css/program.css";
import { Helmet } from 'react-helmet';
import { Link } from "react-scroll";
// import { Link, useLocation } from 'react-router-dom';

import { Container, Row, Col, Accordion, Image } from "react-bootstrap";
import b1 from '../assets/img/seo1.webp';

import b2 from '../assets/img/web1.jpg';

import b3 from '../assets/img/dg.jpg';

import {  clgg, hgs, img1, it_img } from "./mls_constants";
const Programs = () => {
  const data_science=[{title:'Fundamental of AI',value:'Week 2-5'},{title:'Python',value:'Week 2-5'},
  {title:'Basic Excel',value:'Week 6'},
  {title:'SQL',value:'Week 7-9'},
  {title:'Machine Learning',value:'Week 10-14'},
  {title:'Math & Statistics',value:'Week 15-16'},
  {title:'Deep Learning',value:'Week 17-21'}];
  const data_analytics=[{title:'Fundamental of AI',value:'Week 2-5'},{title:'MS Excel',value:'Week 2-5'},
  {title:'Tableau',value:'Week 6'},
  {title:'PowerBI',value:'Week 7-9'},
  {title:'SQL',value:'Week 10-14'},
  {title:'Basic Math & Statistics',value:'Week 15-16'},
  {title:'Python',value:'Week 17-21'},
  {title:'Basic ML',value:'Week 17-21'}];
  const data_engineer=[{title:'Python & SQL',value:'Week 2-5'},
  {title:'Introduction to Big Data, Hadoop & Mapreduce',value:'Week 6'},
  {title:'Hive',value:'Week 7-9'},
  {title:'Scoop & NoSql',value:'Week 10-14'},
  {title:'Kafka & Scala',value:'Week 15-16'},
  {title:'Spark & Big Data Cloud',value:'Week 17-21'}]
 const BasicTable=(props)=>{
  
  return (
    <ul className="Desc">

    {props.data && props.data.map(d=>
    
        <li>{d.title}</li>
      
      )}
  </ul>
  )
 }
  return (
    <div>
      <Navbar />
      <Helmet>
        <meta name="google-site-verification" content="bhOOEzJ-8aaGF_FM3O7nKH4GgUpXIhIk0kr_tPC9FTY" />
        <meta name="title" content="	Best Digital Marketing, Website Design & Development Services in India, USA | Digiweb Softech" />
        <meta name="description" content="Digiweb Softech offers Live, Online Math, English, Science, Coding, SAT, ACT, AP, SSAT, STAAR, and AMC classes for students in grades 2-12 & for adults in USA. The programs of MLyour cheld's academic progress, programs, GUARANTEED. Book a FREE TRIAL now!ress, programs, GUARANTEED. Book a FREE TRIAL now!"/>
        <meta name="keywords" content="Digiweb Softech offers Live, Online Math, English, Science, Coding, SAT, ACT, AP, SSAT, STAAR, and AMC classes for students in grades 2-12. The programs of Digiweb Softech are specifically designed to accelerate your child's academic progress, programs, GUARANTEED.| Digiweb Softech
"/>
        <meta name="robots" content="index, follow" />
        <title>	Best Digital Marketing, Website Design & Development Services in India, USA | Digiweb Softech</title>
      </Helmet>
      <div class="f-template-contact">
        <img src={img1} alt="first Template" />
        {/* <h2 class="our-program-h">Our Program</h2> */}
      </div>
      <div className="main-prg">
        <div className="btn">
          <Link to="1" smooth={true} duration={500} className="yo">
            <h3>Digital Marketing </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="2" smooth={true} duration={500} className="yo">
            <h3>Search Engine Optimization (SEO) </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="3" smooth={true} duration={500} className="yo">
            <h3>Social Media Marketing (SMM)  </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="4" smooth={true} duration={500} className="yo">
            <h3>Email Marketing  </h3>
          </Link>
        </div>

        <div className="btn">
          <Link to="5" smooth={true} duration={500} className="yo">
            <h3>Online Reputation Management  </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="6" smooth={true} duration={500} className="yo">
            <h3>Pay-Per Click Marketing  </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="7" smooth={true} duration={500} className="yo">
            <h3>Web Application Development  </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="8" smooth={true} duration={500} className="yo">
            <h3>Mobile Application Development  </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="9" smooth={true} duration={500} className="yo">
            <h3>Business Process Outsourcing  </h3>
          </Link>
        </div>
        {/* <div className="btn">
          <Link to="test" smooth={true} duration={500} className="yo">
            <h3>Design Courses </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="it" smooth={true} duration={500} className="yo">
            <h3>Stock Crypto & Blockchain Courses </h3>
          </Link>
        </div> */}

        {/* <div className="btn">
          <Link to="college" smooth={true} duration={500} className="yo">
            <h3>College </h3>
          </Link>
        </div> */}
        {/* <div className="mt-10-nav-login-btn">
          <p1 style={{ marginLeft: "23%" }}>2 - 12</p1>
        </div> */}
        <div className="mainsection" style={{ marginTop: "5%", padding: "5%" }}>
          <Row>
            <Col md={12} sm={12} xs={12} class="container">
              <Accordion className="work" defaultActiveKey="0" id="1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h1 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                      Digital Marketing
                      {

                      }
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                  <Row className="p-5"  >
                      <Col md={12} >
                      <ul className="Desc">
<li>Keyword Optimization: Research and optimize relevant keywords for higher visibility.</li>
<li>Quality Backlinks: Build high-quality backlinks to boost website authority.</li>
<li>Content Excellence: Create SEO-friendly, engaging content and optimize internal links.</li>
<li>Technical Precision: Ensure fast loading, mobile-friendly design, and effective site structure.</li>
<li>Local SEO Focus: Enhance local visibility through Google My Business optimization.</li>
<li>Analytics Mastery: Set up Google Analytics, track keyword rankings, and analyze traffic.</li>
<li>User Experience: Improve website navigation, speed, and implement clear call-to-actions.</li>
<li>Adaptation to Updates: Stay current with search engine algorithms and adjust strategies.</li>
<li>Competitor Analysis: Regularly benchmark against competitors for strategic insights.</li>
<li>Transparent Reporting: Provide clear, detailed reports on performance metrics and progress.</li>
</ul>
                      

                     
                      </Col>
                      
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="2">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h1 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                      Search Engine Optimization (SEO)
                      {

                      }
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                  <Row className="p-5"  >
                      
                      <Col md={12} >
                      <ul className="Desc">
<li>Strategic Keywords: Expert research and optimization for maximum visibility.</li>
<li>High-Quality Backlinks: Building authoritative links to enhance website credibility.</li>
<li>Engaging Content: Creation of SEO-friendly, compelling content and internal linking.</li>
<li>Technical Precision: Ensuring fast loading, mobile-friendly design, and solid site structure.</li>
<li>Local SEO Focus: Optimization for local search, including Google My Business.</li>
<li>Data-driven Analytics: Utilizing Google Analytics for insightful performance analysis.</li>
<li>User-Centric Design: Improving site navigation, speed, and user experience.</li>
<li>Algorithm Adaptation: Staying ahead by adjusting strategies with search engine updates.</li>
<li>Competitor Insights: Regular analysis to glean strategic advantages from competitors.</li>
<li>Clear Reporting: Transparent and detailed reporting on performance metrics and progress.</li>
</ul>
                      

                     
                      
                      </Col>
                   
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h1 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                     Social Media Marketing (SMM)
                      {

                      }
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>Strategic Planning: Develop tailored social media strategies aligned with business goals.</li>
<li>Engaging Content: Create compelling posts, graphics, and videos for diverse social platforms.</li>
<li>Targeted Advertising: Run targeted ads on Facebook, Instagram, Twitter, and LinkedIn for specific audiences.</li>
<li>Community Building: Foster engagement through comments, likes, and interactive polls.</li>
<li>Analytics & Insights: Utilize data analytics to measure ROI, track user engagement, and refine strategies.</li>
<li>Brand Awareness: Enhance brand visibility and recognition through social media presence.</li>
<li>Influencer Collaboration: Forge partnerships with influencers for wider reach and credibility.</li>
<li>Timely Updates: Post consistently to maintain audience interest and keep followers informed.</li>
<li>Crisis Management: Handle negative feedback and crises professionally, preserving brand reputation.</li>
<li>Cross-Platform Integration: Seamlessly integrate content across platforms for a cohesive brand message.</li>
</ul>
                      </Col>
                      <br />
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="4">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h3 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                      Email Marketing
                      {

                      }
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>Strategic Campaigns: Craft targeted email strategies for maximum engagement and conversions.</li>
<li>Creative Content: Design visually appealing and compelling email newsletters and promotions.</li>
<li>Segmentation: Divide your audience for personalized, relevant content delivery.</li>
<li>Automation: Set up automated email sequences for timely and tailored customer interactions.</li>
<li>A/B Testing: Optimize campaigns with A/B testing for subject lines, content, and call-to-actions.</li>
<li>Analytics & Insights: Track email open rates, click-through rates, and customer behavior.</li>
<li>Lead Nurturing: Nurture leads through drip campaigns, guiding them through the sales funnel.</li>
<li>Compliance: Ensure emails comply with anti-spam laws and regulations for ethical marketing.</li>
<li>Responsive Design: Create responsive emails that render well on various devices and screen sizes.</li>
<li>ROI Analysis: Provide detailed reports, analyzing the return on investment and campaign effectiveness.</li>
</ul>
                      </Col>
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="5">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h3 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                    Online Reputation Management
                      {

                      }
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>Reputation Analysis: Assess online presence and sentiment across platforms.</li>
<li>Negative Content Suppression: Strategically push down negative search results.</li>
<li>Positive Content Promotion: Create and promote positive, engaging content.</li>
<li>Review Management: Monitor and respond to customer reviews proactively.</li>
<li>Social Media Monitoring: Track brand mentions and engagement on social platforms.</li>
<li>Crisis Management: Develop strategies to handle PR crises and negative publicity.</li>
<li>Brand Monitoring Tools: Utilize advanced tools for real-time brand tracking.</li>
<li>Competitor Analysis: Monitor competitors' online reputation and adjust strategies.</li>
<li>SEO Integration: Implement SEO techniques to boost positive content visibility.</li>
<li>Monthly Reporting: Provide detailed reports on online reputation metrics and improvements.</li>
</ul>
                      </Col>
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="6">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h3 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                    Pay-Per Click Marketing
                      {

                      }
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>Strategic Campaigns: Develop targeted Google Ads campaigns for optimal reach.</li>
<li>Compelling Ad Copy: Craft persuasive ad copies and perform A/B testing for effectiveness.</li>
<li>Keyword Research: Identify high-performing keywords to maximize ad visibility.</li>
<li>Ad Extensions: Implement site link, callout, and location extensions for enhanced engagement.</li>
<li>Budget Optimization: Efficiently manage budgets to maximize ROI and minimize wastage.</li>
<li>Remarketing Strategies: Create tailored ads to retarget visitors for increased conversions.</li>
<li>Analytics Integration: Integrate Google Analytics to track campaign performance and user behavior.</li>
<li>Conversion Tracking: Implement precise conversion tracking to measure campaign success.</li>
<li>Competitor Analysis: Analyze competitors' strategies for informed decision-making.</li>
<li>Continuous Optimization: Regularly monitor and adjust campaigns based on real-time data for optimal results.</li>
</ul>
                      </Col>
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="work" defaultActiveKey="0" id="7">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h3 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                    Web Application Development
                      {

                      }
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>Customized Solutions: Tailored web applications designed to meet specific business needs.</li>
<li>User-Centric Design: Intuitive and visually appealing interfaces for seamless user experiences.</li>
<li>Scalability: Build applications that can scale with your business growth and evolving requirements.</li>
<li>Cross-Platform Compatibility: Develop applications compatible across various devices and platforms.</li>
<li>Security Measures: Implement robust security protocols to safeguard data and user interactions.</li>
<li>Performance Optimization: Optimize loading times and overall performance for enhanced user satisfaction.</li>
<li>Integration Capabilities: Seamlessly integrate third-party APIs and services for extended functionality.</li>
<li>Responsive Support: Provide ongoing support, maintenance, and updates for a reliable user experience.</li>
<li>Agile Development: Agile methodologies for flexible, iterative development and quick feature rollouts.</li>
<li>Quality Assurance: Rigorous testing procedures to ensure bug-free, reliable, and high-quality applications.</li>
</ul>
                      </Col>
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="8">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h3 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                    Mobile Application Development
                      {

                      }
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>Custom App Solutions: Tailored mobile apps designed for your unique business needs.</li>
<li>Cross-Platform Expertise: Development for iOS, Android, and hybrid platforms.</li>
<li>User-Centric Design: Intuitive interfaces ensuring exceptional user experience.</li>
<li>Feature-Rich Development: Integration of advanced features for enhanced functionality.</li>
<li>Scalability & Performance: Apps built to scale, ensuring optimal performance under any load.</li>
<li>Security Measures: Implement robust security protocols to safeguard user data.</li>
<li>Testing & Quality Assurance: Rigorous testing to guarantee bug-free, flawless applications.</li>
<li>Regular Updates: Continuous support, updates, and improvements for long-term reliability.</li>
<li>API Integration: Seamless integration with third-party services for enhanced capabilities.</li>
<li>Post-Launch Support: Ongoing support, troubleshooting, and maintenance post-app launch.</li>
</ul>
                      </Col>
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="9">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h3 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                      Business Process Outsourcing
                      {

                      }
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>Customer Support: Deliver exceptional customer service through phone, email, and chat support.</li>
<li>Data Entry and Management: Accurate and efficient data entry, processing, and database management.</li>
<li>Multilingual Support: Cater to a global audience with multilingual customer support solutions.</li>
<li>Back-Office Support: Handle administrative tasks, billing, and document processing seamlessly.</li>
<li>Quality Assurance: Implement rigorous quality checks to ensure high accuracy and client satisfaction.</li>
<li>Process Optimization: Streamline workflows and enhance operational efficiency for clients.</li>
<li>24/7 Availability: Provide round-the-clock support for clients and their customers in different time zones.</li>
<li>Scalability: Flexible services that can scale up or down based on client requirements and business growth.</li>
<li>Technology Integration: Utilize advanced software and tools to optimize BPO processes for clients.</li>
<li>Custom Solutions: Tailor BPO services to meet the unique needs of various industries and businesses.</li>
</ul>
                      </Col>
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>

          <div style={{ marginTop: "5%" }}></div>

          <div className="white">
            <Container fluid={true}>
              <Row >
                {/* <div className="prefChoice"> */}
                  <Col md="12" xs="12" sm="12">
                  <h3 className="program_div">Why is Digiweb Softech the preferred choice for effective learning and academic success?</h3>
                  </Col>
                {/* </div> */}

                <Col
                  md="4" sm="11" xs="11"
                  text-center
                  mt="5"
                  style={{ marginTop: "10vh" }}
                >
                  <div className="bottomSection">
                    <img
                      src={b1}
                      className="img-fluid"
                      alt="course"
                    />
                    <h3 className="Ind-learn">DigiWeb Techsoft SEO</h3>
                    <p className="Flex-learn">
                    Digiweb Softech is the preferred choice for SEO because we have a deep understanding of search engine algorithms and a commitment to delivering results. We employ white-hat SEO techniques, staying up-to-date with the latest industry trends, which ensures that our clients' websites rank higher in search results and drive valuable organic traffic.
                    </p>
                    <br />
                  </div>
                </Col>
                <Col md="4" sm="11" xs="11" style={{ marginTop: "10vh" }}>
                  <div className="bottomSection">
                    <img
                      src={b2}
                      className="img-fluid"
                      alt="course"
                    />
                    <h3 className="Ind-learn">DigiWeb Techsoft  for Website & Mobile</h3>
                    <p className="Flex-learn">
                    DigiWeb Techsoft stands out as the preferred choice for Mobile App Development due to our proficiency in crafting cutting-edge mobile applications. Our experienced app developers specialize in both Android and iOS platforms, delivering user-friendly and innovative apps that meet the diverse needs of our clients. We leverage the latest technologies and industry best practices to create mobile apps that enhance user engagement and provide seamless experiences, making us the go-to partner for businesses seeking top-notch mobile solutions.
                    </p>
                    <br />
                    <br />
                  </div>
                </Col>
                <Col md="4" sm="11" xs="11" style={{ marginTop: "10vh" }}>
                  <div className="bottomSection">
                    <img
                      src={b3}
                      className="img-fluid"
                      alt="empower"
                    />
                    <h3 className="Ind-learn">Digital Marketing </h3>
                    <p className="Flex-learn">
                    DigiWeb Techsoft is the preferred choice for Digital Marketing because we bring a unique blend of creativity, expertise, and data-driven strategies to the table. Our dedicated team understands the evolving digital landscape, enabling us to create highly effective marketing campaigns tailored to our clients' specific needs. With a focus on ROI-driven results, innovative techniques, and a commitment to client success, DigiWeb Techsoft stands out as the top choice for businesses seeking impactful and result-oriented digital marketing solutions.
                    </p>
                    <br />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Programs;
