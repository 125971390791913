import React, { useEffect, useState } from "react";
import "../assets/css/index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AOS from "aos";
import "aos/dist/aos.css";

import Footer from "../components/footer";
import Courses from "./courses.js";
import slider1 from '../assets/img/9th.jpeg';
import slider2 from '../assets/img/10th.jpeg';
import slider3 from '../assets/img/11th.jpeg';
import slider4 from '../assets/img/12th.jpeg';
import slider5 from '../assets/img/5.jpeg';
import slider6 from '../assets/img/6.jpeg';
import slider7 from '../assets/img/4.jpeg';

import image1 from '../assets/img/exp.webp';
import image2 from '../assets/img/data-driven.jpg';
import image3 from '../assets/img/ideas.jpg';
import Per_learning from '../assets/img/th.jpg';






import CommentsSlide from "../components/comments_slide";
import { redirect, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../components/navbar";
import { Accordion, Col, Container, Image, Row } from "react-bootstrap";
import { ChallengingEnv, Comp_Programs, Confidence_Building, ExpertInstructors, Flexible, Lifelong_Skills, Proven_results, image11, image13 } from "./mls_constants";
function Index() {
  const myInlineStyle = {
    fontSize: "16px",
    color: "blue",
    fontWeight: "bold",
    size: "100px",
    // Add more CSS properties as needed
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Add this line to enable autoplay
    autoplaySpeed: 4000,
  };
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
    });
  }, []);
  const A_benefit = (props) => {
    const labelling = props.label.split(" ");
    return (
      <div data-aos="zoom-in" className="benefit">
        <img src={props.image} alt="Zoom" />
        <p>
          {labelling[0]}
          <br />
          {labelling[1]}
        </p>
      </div>
    );
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contactus");
  };
  const FreeClassButton = () => {
    return (
      <div class="free_class">
        <button onClick={handleClick}>Get a Quote Today</button>
      </div>
    );
  };
  const myStyle = {
    display: "flex",
    justifyContent: "center",
  };
  const [youtubeID] = useState("XpScgwRKpgs");
  console.log(`https://youtube.com/embed/${youtubeID}?autoplay=0`);
  return (
    <div>
      <Navbar />
      <Helmet>
        <meta name="google-site-verification" content="bhOOEzJ-8aaGF_FM3O7nKH4GgUpXIhIk0kr_tPC9FTY" />
        <meta name="title" content="Live 1:1 Online Classes for Kids  (Grade 2-12) | Digiweb Softech" />
        <meta name="description" content="Digiweb Softech offers Live, Online Math, English, Science, Coding, SAT, ACT, AP, SSAT, STAAR, and AMC classes for students in grades 2-12. The programs of Digiweb Softech are specifically designed to accelerate your child's academic progress, programs, GUARANTEED. Book a FREE TRIAL now!" />
        <meta name="keywords" content="Digiweb Softech, MLS, Digiweb Softech offers Live, Online Math, English, Science, Coding, SAT, ACT, AP, SSAT, STAAR, and AMC classes for students in grades 2-12. These programs are specifically designed to accelerate your child's academic progress, programs, GUARANTEED." />
        <title>Best Digital Marketing,Website Design & Development Company in India, USA| Digiweb Softech</title>
      </Helmet>
      <div className="Slideshow">
        <Slider {...settings}>
          <div className="f-template" style={myInlineStyle}>
            <img src={slider1} alt="Digiweb Softech - Math, English, Science & Coding - United States" title="Digiweb Softech - Math, English, Science & Coding - United States" />
          </div>
          <div className="f-template">
            <img src={slider2} alt="Digiweb Softech - US Curriculum - United States" title="Digiweb Softech - US Curriculum - United States" />
          </div>
          <div className="f-template">
            <img src={slider3} alt="Digiweb Softech - United States" title="Digiweb Softech - United States" />
          </div>
          <div className="f-template">
            <img src={slider4} alt="Digiweb Softech - SAT, PSAT, ACT Exam - United States" title="Digiweb Softech - SAT, PSAT, ACT Exam - United States" />
          </div>
          <div className="f-template">
            <img src={slider7} alt="Digiweb Softech - Physics, Pre Calculus, Economics, Statistics, Psychology, Computer Science, Chemistry - United States" title="Digiweb Softech - Physics, Pre Calculus, Economics, Statistics, Psychology, Computer Science, Chemistry - United States" />
          </div>
          <div className="f-template">
            <img src={slider5} alt="Digiweb Softech - Pre Algebra, Geometry, Algebra 1 & 2, Pre Calculus, Calculus, ELA, Science - United States" title="Digiweb Softech - Pre Algebra, Geometry, Algebra 1 & 2, Pre Calculus, Calculus, ELA, Science - United States" />
          </div>
          <div className="f-template">
            <img src={slider6} alt="Digiweb Softech - Physics, Pre Calculus, Economics, Statistics, Psychology, Computer Science, Chemistry - United States" title="Digiweb Softech - Physics, Pre Calculus, Economics, Statistics, Psychology, Computer Science, Chemistry - United States" />
          </div>
        
        </Slider>
      </div>
      <br />
      <br />
      <div className="find-live">
        <div className="brfdesc">
          <a href="#">
          🌟 Welcome to DigiWeb Softech: Where Innovation Meets Expertise! 🚀 Elevate your online presence with our cutting-edge Digital Marketing, Website Design & Development Services, meticulously crafted by our talented team of IIT Alumni. From India to the USA, we create captivating online experiences tailored to your needs. Let's transform your digital journey together! 🚀
          </a>
        </div>
        <div className="width-line-space">
          <div className="yellowBtn">&nbsp;</div>
        </div>
        <br />
        <div className="midBanner">
          {/* <h4>Our Programs For 2-12</h4> */}
        </div>
      </div>
      <div style={myStyle}>
        <FreeClassButton />
      </div>
      <div className="boxes">
      <div className="box">
          <h2>Digital Marketing</h2>
          <p>Boost your brand online with DigiWeb Softech! 🚀 Expert in SEO, PPC, social media, and web design. Let's make your digital dreams a reality. Contact us now!</p>
        </div>
        <div className="box">
          <h2>Search Engine Optimization (SEO)</h2>
          <p>Maximize your online visibility with DigiWeb Softech's SEO expertise! 🌐 Our team ensures top rankings, increased traffic, and enhanced brand presence. Ready to dominate search results? Contact us today.</p>
        </div>
        <div className="box">
          <h2>Social Media Marketing (SMM)</h2>
          <p>Supercharge your social media presence with DigiWeb Softech! 🌟 Expert in creating engaging content, targeted ads, and data-driven strategies. Let's amplify your brand's voice online. Connect with us today!</p>
        </div>
        <div className="box">
          <h2>Email Marketing</h2>
          <p>Supercharge your email marketing with DigiWeb Softech! ✉️ Tailored campaigns, engaging content, and high conversions are our specialties. Let's create compelling emails that resonate with your audience. Reach out today and elevate your email marketing game!.</p>
        </div>
        <div className="box">
          <h2>Online Reputation Management </h2>
          <p>Guard your online reputation with DigiWeb Softech! 🛡️ Our expert Online Reputation Management services ensure a positive digital image for your brand. From monitoring to damage control, we've got you covered. Let's safeguard your reputation together. Contact us today!</p>
         </div>
        <div className="box">
          <h2>Pay-Per Click Marketing</h2>
          <p>Maximize your ROI with DigiWeb Softech's PPC expertise! 💰 Targeted ads, measurable results, and increased traffic – we've got your PPC needs covered. Let's boost your business together. Get started now.</p>
        </div>
        <div className="box">
          <h2>Web & Mobile Application Development</h2>
          <p>
          Turn your app idea into reality with DigiWeb Softech! 📱 Expert in Web & Mobile App Development. From sleek websites to cutting-edge mobile apps, we create seamless user experiences. Let's bring your digital vision to life. Get in touch today</p>
        </div>
        <div className="box">
          <h2>Business Process Outsourcing</h2>
          <p>Optimize your operations with DigiWeb Softech's BPO services! 🌟 From customer support to data entry, we handle it all efficiently. Experience seamless outsourcing and elevate your business. Get in touch today!</p>
        </div>
        {/* <div className="box">
          <h2>Design Courses</h2>
          <p>Elevate creativity with design courses, exploring graphic design, UX/UI, digital art, and branding. Cultivate visual storytelling skills and empower students to craft compelling, user-centered experiences in the ever-evolving design landscape.</p>
        </div>
        <div className="box">
          <h2>Stock Crypto & Blockchain Courses</h2>
          <p>Delve into the world of finance with stock, crypto, and blockchain courses, equipping students with trading strategies, market analysis, and blockchain technology insights. Empower them to navigate the complexities of financial markets and emerging digital currencies with confidence.</p>
        </div> */}
      </div>
      <div className="features">
        <div className="features-h">Main Features</div>

        <div className="features-sub">
          <div className="features-img" data-aos="fade-right">
            <img src={image13} alt="features img" />
          </div>
          <div className="features-content">
            <div className="features-content-2" data-aos="fade-left">
              <div className="features-content-img">
                <img src={image1} alt="img" />
              </div>
              <div className="features-content-text">
                <a href="#">Expertise and Specialization</a>
              </div>
            </div>

            <div className="features-content-2" data-aos="fade-left">
              <div className="features-content-img">
                <img src={image2} alt="img" />
              </div>
              <div className="features-content-text">
                <a href="#">Data-Driven Approach</a>
              </div>
            </div>

            <div className="features-content-2" data-aos="fade-left">
              <div className="features-content-img">
                <img src={image3} alt="img" />
              </div>
              <div className="features-content-text">
                <a href="#">Creativity and Innovation </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="popular">
        <div className="popular-head">
         <h2 style={{marginLeft:"50%"}}>Our Services</h2>
        </div>
        <Courses />
      </div>
      <div style={myStyle}>
        <FreeClassButton />
      </div>

      <div className="brand-moto">
        <div className="brand-moto-message">
          <h2>Why the Digiweb Softech?</h2>
          <p>
          Welcome to DigiWeb Softech, the fastest growing Digital Marketing Company in India, founded by IIT Alumni. We specialize in empowering your business in the digital realm, offering a comprehensive range of services including SEO, SMO, SMM, SEM, Website Design & Development, as well as Google and Meta Ads Campaign management.
<br/>
Choosing the right partner for your online marketing is paramount, and we are the perfect fit for your needs. Our team of experts possesses extensive knowledge and experience in the field of digital marketing. We stay up-to-date with the latest trends and strategies, ensuring that your business receives the latest and best possible marketing solutions.
          </p>
        </div>

        {/* <div>
      <iframe
          poster={image11}
          className="YoutubeBox"
          src={`https://www.youtube.com/embed/${youtubeID}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          crossOrigin="anonymous"
        ></iframe>
      </div> */}
      </div>
      
      <div className="benefits-container">
      <div className="brand-moto">
        <div className="brand-moto-message" style={{width:"65%"}}>
          <h2>Benefits of Hiring us </h2>
          <p>
          Benefits of Hiring  Digiweb Softech For Your Digital Marketing & Website Design Services | Digiweb Softech.
          </p>
        </div>
          
        </div>
      

        <div className="benefits">
          <A_benefit
            className="bnfts"
            image={ExpertInstructors}
            label="Expertise and Skill"
          />
          <A_benefit
            className="bnfts"
            image={Per_learning}
            label="Cost-Effectiveness"
          />
          <A_benefit
            className="bnfts"
            image={Comp_Programs}
            label="Scalability"
          />
          <A_benefit
            className="bnfts"
            image={Proven_results}
            label="Measurable Results"
          />
  
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="parents-love">
        <CommentsSlide />
      </div>
      <br />
      <br />
      <br />
      <div style={myStyle}>
        <FreeClassButton />
      </div>
      <h2 className="acc-section">
        Common Faqs </h2>
      <Container id="accord-home" fluid={false} style={{ "marginTop": "5%" }}>

        <Row>
          <Col md={12} sm={12}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="acc-header">What services does DigiWeb Techsoft offer?</Accordion.Header>
                <Accordion.Body>
                DigiWeb Techsoft provides a wide range of services including Digital Marketing, Website Design & Development, SEO (Search Engine Optimization), SMO (Social Media Optimization), PPC (Pay-Per-Click) campaigns, Meta ads, Mobile Application Development, and Business Process Outsourcing (BPO) solutions.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="acc-header">  Where is DigiWeb Techsoft located?</Accordion.Header>
                <Accordion.Body>
                We have a strong presence in both India and the USA, allowing us to serve clients effectively in these regions.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header className="acc-header" >Could you provide us with the contact information to get course details of Digiweb Softech?</Accordion.Header>
                <Accordion.Body>
                 You can contact Digiweb Softech by 
                 
                 <li>phone at <a href="#">+91 9079476942</a> or via WhatsApp at the same number.</li> 
                  <li>Initiate a WhatsApp chat through this link: <a href="https://wa.me/message/XMS5KMWBGQZLG1">WhatsApp</a></li>
                 <li>If you prefer, you can also use the Contact Us form on the Digiweb Softech website: <a href="https://digiwebsoftech.com/contactus">contact Digiweb Softech</a></li> 
                 <li>Additionally, you can reach out to us through email at <a href="mailto:digiwebsoftech@gmail.com.com">digiwebsoftech@gmail.com.com</a> or <a href="mailto:digiwebsoftech@gmail.com.com">digiwebsoftech@gmail.com.com</a> </li> 
                 We're here to assist you!
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header className="acc-header"> How can Digital Marketing benefit my business?</Accordion.Header>
                <Accordion.Body>
                Digital Marketing helps increase your online visibility, attract targeted traffic, engage potential customers, and boost sales. It includes various strategies like SEO, SMO, PPC, and Meta ads to enhance your online presence.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header className="acc-header">What is SEO, and why is it important for my website?</Accordion.Header>
                <Accordion.Body>
                SEO stands for Search Engine Optimization. It's the process of optimizing your website to rank higher in search engine results pages (SERPs). SEO is crucial as it helps improve your website's visibility, driving organic traffic and enhancing user experience.
                </Accordion.Body>
                <Accordion.Item eventKey="6">
                  <Accordion.Header className="acc-header">Can you redesign an existing website for my business?</Accordion.Header>
                  <Accordion.Body>
                  Absolutely! DigiWeb Techsoft specializes in website redesign and development. We can revamp your existing website, making it more user-friendly, visually appealing, and optimized for search engines.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header className="acc-header"> What sets DigiWeb Techsoft apart from other digital marketing agencies?</Accordion.Header>
                  <Accordion.Body>
                  At DigiWeb Techsoft, we combine creativity, technical expertise, and strategic thinking to deliver exceptional results. Our dedicated team works closely with clients, understanding their goals and crafting customized solutions to achieve sustainable business growth.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header className="acc-header">How can I get started with DigiWeb Techsoft's services?</Accordion.Header>
                  <Accordion.Body>
                  Getting started is easy! Simply contact us through our website or reach out via phone or email. Our team will schedule a consultation to discuss your requirements, propose tailored solutions, and kick-start your digital journey with us.
                  </Accordion.Body>
                </Accordion.Item> 
                <Accordion.Item eventKey="8">
                  <Accordion.Header className="acc-header">How can I contact your coaching classes for further inquiries?</Accordion.Header>
                  <Accordion.Body>
                  Absolutely! Our Digiweb Softech subscription For any further inquiries, you can contact us through our Contact Us page on the website. Alternatively, you can email us at [digiwebsoftech@gmail.com.com] or call us at [+91 9079476942]. We're here to assist you.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>

      </Container>


        <Footer />
    </div>
  );
}
export default Index;
