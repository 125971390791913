import React, { Component } from "react";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../assets/css/program.css";
import { Helmet } from 'react-helmet';
import { Link } from "react-scroll";
// import { Link, useLocation } from 'react-router-dom';

import { Container, Row, Col, Accordion, Image } from "react-bootstrap";

import { b1, b2, b3, clgg, hgs, img1, it_img } from "./mls_constants";
const Pricing = () => {
  const data_science=[{title:'Fundamental of AI',value:'Week 2-5'},{title:'Python',value:'Week 2-5'},
  {title:'Basic Excel',value:'Week 6'},
  {title:'SQL',value:'Week 7-9'},
  {title:'Machine Learning',value:'Week 10-14'},
  {title:'Math & Statistics',value:'Week 15-16'},
  {title:'Deep Learning',value:'Week 17-21'}];
  const data_analytics=[{title:'Fundamental of AI',value:'Week 2-5'},{title:'MS Excel',value:'Week 2-5'},
  {title:'Tableau',value:'Week 6'},
  {title:'PowerBI',value:'Week 7-9'},
  {title:'SQL',value:'Week 10-14'},
  {title:'Basic Math & Statistics',value:'Week 15-16'},
  {title:'Python',value:'Week 17-21'},
  {title:'Basic ML',value:'Week 17-21'}];
  const data_engineer=[{title:'Python & SQL',value:'Week 2-5'},
  {title:'Introduction to Big Data, Hadoop & Mapreduce',value:'Week 6'},
  {title:'Hive',value:'Week 7-9'},
  {title:'Scoop & NoSql',value:'Week 10-14'},
  {title:'Kafka & Scala',value:'Week 15-16'},
  {title:'Spark & Big Data Cloud',value:'Week 17-21'}]
 const BasicTable=(props)=>{
  
  return (
    <ul className="Desc">

    {props.data && props.data.map(d=>
    
        <li>{d.title}</li>
      
      )}
  </ul>
  )
 }
  return (
    <div>
      <Navbar />
      <Helmet>
        <meta name="google-site-verification" content="bhOOEzJ-8aaGF_FM3O7nKH4GgUpXIhIk0kr_tPC9FTY" />
        <meta name="title" content="	Best Digital Marketing, Website Design & Development Services in India, USA | Digiweb Softech" />
        <meta name="description" content="Digiweb Softech offers Live, Online Math, English, Science, Coding, SAT, ACT, AP, SSAT, STAAR, and AMC classes for students in grades 2-12 & for adults in USA. The programs of MLyour cheld's academic progress, programs, GUARANTEED. Book a FREE TRIAL now!ress, programs, GUARANTEED. Book a FREE TRIAL now!"/>
        <meta name="keywords" content="Digiweb Softech offers Live, Online Math, English, Science, Coding, SAT, ACT, AP, SSAT, STAAR, and AMC classes for students in grades 2-12. The programs of Digiweb Softech are specifically designed to accelerate your child's academic progress, programs, GUARANTEED.| Digiweb Softech
"/>
        <meta name="robots" content="index, follow" />
        <title>	Best Digital Marketing, Website Design & Development Services in India, USA | Digiweb Softech</title>
      </Helmet>
      <div class="f-template-contact">
        <img src={img1} alt="first Template" />
        {/* <h2 class="our-program-h">Our Program</h2> */}
      </div>
      <div className="main-prg">
        <div className="btn">
          <Link to="1" smooth={true} duration={500} className="yo">
            <h3>Information Technology </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="2" smooth={true} duration={500} className="yo">
            <h3>Healthcare </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="3" smooth={true} duration={500} className="yo">
            <h3>Education  </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="4" smooth={true} duration={500} className="yo">
            <h3>Manufacturing </h3>
          </Link>
        </div>

        <div className="btn">
          <Link to="5" smooth={true} duration={500} className="yo">
            <h3>Logistics & Distribution </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="6" smooth={true} duration={500} className="yo">
            <h3>Engineering & Constrution</h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="7" smooth={true} duration={500} className="yo">
            <h3>Retail  </h3>
          </Link>
        </div>
        <div className="btn">
          <Link to="8" smooth={true} duration={500} className="yo">
            <h3>Insurance  </h3>
          </Link>
        </div>
        {/* <div className="btn">
          <Link to="9" smooth={true} duration={500} className="yo">
            <h3>Travel & Transport  </h3>
          </Link>
        </div> */}
        <div className="mainsection" style={{ marginTop: "5%", padding: "5%" }}>
          <Row>
            <Col md={12} sm={12} xs={12} class="container">
              <Accordion className="work" defaultActiveKey="0" id="1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h1 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                      Information Technology
                      {

                      }
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                  <Row className="p-5"  >
                      <Col md={12} >
                      <ul className="Desc">
<li>IT Services: Leverage cutting-edge technology solutions for streamlined operations.</li>
<li>Cybersecurity: Protect sensitive data with robust security protocols and threat detection.</li>
<li>Cloud Computing: Enhance scalability and accessibility with cloud-based infrastructure.</li>
<li>Software Development: Build customized applications tailored to business needs.</li>
<li>Network Solutions: Optimize network performance for seamless communication and collaboration.</li>
<li>IT Consultancy: Provide expert advice on IT strategies and digital transformation initiatives.</li>
</ul>
                      

                     
                      </Col>
                      
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="2">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h1 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                      Healthcare
                      {

                      }
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                  <Row className="p-5"  >
                      
                      <Col md={12} >
                      <ul className="Desc">
<li>Telemedicine Solutions: Facilitate remote consultations and enhance patient accessibility.</li>
<li>Electronic Health Records (EHR): Ensure secure and organized digital patient data management.</li>
<li>Healthcare Mobile Apps: Develop user-friendly apps for appointments, prescriptions, and health monitoring.</li>
<li>Medical Website Development: Create informative, user-centric websites for clinics and hospitals.</li>
<li>Healthcare Analytics: Utilize data insights to improve patient care and operational efficiency.</li>
<li>Compliance & Security: Ensure HIPAA compliance and robust cybersecurity measures for data protection.</li>
</ul>
                      

                     
                      
                      </Col>
                   
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h1 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                     Education
                      {

                      }
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>E-learning Platforms: Develop interactive and engaging online learning environments.</li>
<li>Custom Learning Solutions: Tailor educational software and apps for unique teaching methods.</li>
<li>Student Portals: Create intuitive portals for easy access to resources, grades, and communication.</li>
<li>Virtual Classrooms: Enable live, interactive sessions with video conferencing and collaboration tools.</li>
<li>LMS Integration: Integrate Learning Management Systems for efficient course management.</li>
<li>Educational Apps: Design user-friendly mobile apps for interactive and on-the-go learning experience</li>
</ul>
                      </Col>
                      <br />
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="4">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h3 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                      Manufacturing
                      {

                      }
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>Customized Solutions: Tailor digital strategies to enhance manufacturing processes and efficiency.</li>
<li>Supply Chain Optimization: Streamline logistics and inventory management for improved productivity.</li>
<li>Product Showcase: Create visually appealing websites to display products and attract buyers.</li>
<li>Process Automation: Implement automation tools for precision and reduced operational costs.</li>
<li>Quality Control: Ensure adherence to industry standards through digital monitoring and analytics.</li>
<li>Global Reach: Expand market reach with online presence, reaching potential clients worldwide.</li>
</ul>
                      </Col>
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="5">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h3 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                    Logistics & Distributions
                      {

                      }
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>Supply Chain Optimization: Streamline logistics processes for efficient inventory management and reduced costs.</li>
<li>Warehousing Solutions: Maximize storage efficiency with advanced warehouse management systems.</li>
<li>Transportation Management: Ensure timely deliveries and route optimization for cost-effective transportation.</li>
<li>Inventory Tracking: Implement real-time tracking systems to monitor stock levels and minimize losses.</li>
<li>Customized Logistics: Tailor solutions for specific industries, optimizing distribution networks accordingly.</li>
<li>Global Freight Services: Facilitate international shipping with comprehensive freight forwarding and customs clearance.</li>
</ul>
                      </Col>
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="6">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h3 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                    Engineering & Constrution
                      {

                      }
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>Structural Engineering: Design robust and safe structures for various construction projects.</li>
<li>Civil Engineering: Plan and execute civil infrastructure projects with precision and expertise.</li>
<li>Architectural Design: Create innovative and aesthetic architectural designs tailored to client requirements.</li>
<li>Construction Management: Efficiently oversee construction projects from inception to completion, ensuring quality and timeliness.</li>
<li>Infrastructure Development: Contribute to the growth of communities through well-planned infrastructure development.</li>
<li>Environmental Engineering: Implement eco-friendly solutions and sustainable practices in construction projects, promoting environmental conservation.</li>
</ul>
                      </Col>
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="work" defaultActiveKey="0" id="7">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h3 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                    Retail
                      {

                      }
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>E-commerce Platforms: Create visually appealing and user-friendly online stores for seamless shopping experiences.</li>
<li>Mobile Commerce: Optimize websites for mobile devices, ensuring convenient browsing and purchasing.</li>
<li>Inventory Management: Implement efficient systems to track stock levels and manage product availability.</li>
<li>Customer Loyalty Programs: Develop engaging loyalty schemes to retain customers and drive repeat sales.</li>
<li>Point of Sale (POS) Integration: Integrate POS systems with online platforms for synchronized sales data.</li>
<li>Data Analytics: Utilize data-driven insights to understand customer behavior and enhance marketing strategies.</li>
</ul>
                      </Col>
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="work" defaultActiveKey="0" id="8">
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{ background: "white" }}>
                    {" "}
                    <h3 className="rowsDesc">
                      <span>
                        <Image
                          src={hgs}
                          alt="img"
                          className="img-fluid"
                          width="40"
                          style={{ marginTop: "-20px" }}
                          fluid
                        />
                      </span>{" "}
                    Insurance
                      {

                      }
                    </h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                      <ul className="Desc">
<li>Insurance Solutions: Tailored policies and digital platforms for seamless customer experience.</li>
<li>Policy Management: Efficient tools for policy issuance, claims processing, and client communication.</li>
<li>Data Security: Robust encryption and data management to ensure customer privacy and compliance.</li>
<li>Mobile Apps: User-friendly insurance apps for policy access, claims reporting, and customer support.</li>
<li>Risk Assessment: Advanced analytics for accurate risk evaluation and personalized coverage options.</li>
<li>Customer Support: Dedicated helplines, chat support, and online resources for customer assistance.</li>
</ul>
                      </Col>
                      <hr />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

            
            </Col>
          </Row>


        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Pricing;
