import React from "react";
// import image8 from "../assets/img/icon7 (1).png

// import image13 from "../assets/img/temp2.png";
// import image14 from "../assets/img/temp3.png";

import "../assets/css/index.css";
import { pc1, pc2, pc3, pc4, pc5, pc6, pc7, java } from "./mls_constants";
// import DataScience from '../assets/img/dataScience.jpg';
import WebDev from '../assets/img/webDev.webp';
import SoftTest from '../assets/img/SoftTest.jpeg';
import DigitalMarketing from '../assets/img/DigitalMarketing.jpg';
import DSA from '../assets/img/DSA.jpeg';
import DevOps from '../assets/img/DevOps.jpg';

import Stem from '../assets/img/stem.png';
import UIUX from '../assets/img/uiux.jpg';
import Blockchain from '../assets/img/blockchain.jpg';
import math1 from '../assets/img/math10.jpeg';
import math2 from '../assets/img/math9.jpeg';
import math3 from '../assets/img/math11.jpeg';
import math4 from '../assets/img/math12.jpeg';

import course1 from '../assets/img/seo.jpg';
import course2 from '../assets/img/smo.png';
import course3 from '../assets/img/metaads.jpg';
import course4 from '../assets/img/ppc.webp';
import course5 from '../assets/img/website.jpg';

import course6 from '../assets/img/mobile.jpg';
import course7 from '../assets/img/outsource.jpg';



function render(name, poster, link) {
  const className = "course " + name;
  const image = poster;
  return (
    <div class={className}>
      <img src={image} alt={className} href={link}></img>
      {/* <a href={link}>{name}</a> */}
    </div>
  );
}
function Courses() {
  return (
    <div class="courses">
      <div class="slide slide-wrapper">
        {render("Data Science", course1)}
        {render("Web Dev", course2)}
        {render("Software Test", course3)}
        {render("Digital Marketing", course4)}
        {render("DSA", course5)}
        {render("Devops", course6)}
        {render("Stem", course7)}
        {/* {render("UIUX", UIUX)}
        {render("Blockchain", Blockchain)} */}
      </div>
    </div>
  );
}
export default Courses;
