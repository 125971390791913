import React from "react";
import { Carousel } from "react-bootstrap";
import "../assets/css/index.css";
const comment = (comment, name) => {
  return (
    <div className="comment-body">
      <div className="comment-content">{comment}</div>
      <span className="commenter">-{name}</span>
    </div>
  );
};
const CommentCarousel = () => {
  const comments = [
    {
      id: 1,
      comment: comment(
        "🌟 Mr. Ritik's incredibly impressed with the SEO and SMO services provided by DigiWeb Techsoft. Their team’s expertise and dedication have significantly boosted our online presence. With their strategic approach and in-depth knowledge, our website now ranks higher in search results and our social media engagement has soared. Thank you, DigiWeb Techsoft, for your outstanding services and for driving valuable traffic to our business!🚀✨"
      ),
    },
    {
      id: 2,
      comment: comment(
        "Mr. Ritik from Digiweb Softech is a game-changer! DigiWeb Techsoft has played a pivotal role in our online advertising efforts. Their PPC and Meta Ads campaigns have not only increased our website traffic but also resulted in higher conversion rates. The team's attention to detail and data-driven approach have maximized our ROI. We highly recommend DigiWeb Techsoft for their exceptional skills in managing pay-per-click campaigns that deliver tangible results 🌟"
      ),
    },
    {
      id: 3,
      comment: comment(
        " We turned to DigiWeb Techsoft for our website and mobile app development needs, and we couldn’t be happier with the results. Their developers are not only highly skilled but also incredibly creative. They brought our vision to life, creating a user-friendly website and mobile app that exceeded our expectations. The entire process was smooth, and their post-launch support has been outstanding. Thank you, DigiWeb Techsoft, for your professionalism and expertise!"
      ),
    },
   
    {
      id: 5,
      comment: comment(
        "DigiWeb Techsoft’s Business Process Outsourcing services have streamlined our operations and improved our efficiency significantly. Their dedicated team handled our tasks with precision and reliability, allowing us to focus on core business activities. The seamless communication and timely delivery of projects have made them our trusted outsourcing partner. We are grateful for their excellent service, and we look forward to a long-lasting partnership."
      ),
    },
    // Add more comments as needed
  ];

  return (
    <div className="carousell">
      <h1>Parents and students love us!</h1>
      <Carousel>
        {comments.map((comment) => (
          <Carousel.Item key={comment.id}>{comment.comment}</Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CommentCarousel;
