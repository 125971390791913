import React, { Component, useState } from "react";
import AOS from "aos";

import Footer from "../components/footer";
import Navbar from "../components/navbar";
import { Container, Row, Col, Image } from "react-bootstrap";
import Whatsapp_icon from "../components/whatsapp_icon";
import Mobile_Navbar from "../components/mobile_navbar";
import '../assets/css/studentJob.css';
import { Helmet } from 'react-helmet';
import { first_img1 } from "./mls_constants";
import mission from '../assets/img/mission.jpg'
import vission from '../assets/img/vission.jpg'
import values from '../assets/img/values.jpg'


const stars = (num) => {
  const stars_arr = [];
  for (let i = 0; i < num; i++) {
    stars_arr.push(<span className="star">&#9733;</span>);
  }
  return stars_arr;
};
const comment = (comment, name, stars_arr) => {
  return (
    <>
      <Helmet>
        <meta name="google-site-verification" content="bhOOEzJ-8aaGF_FM3O7nKH4GgUpXIhIk0kr_tPC9FTY" />
        <meta name="title" content="Success stories of Digiweb Softech Students & happy Parents Feedback | Digiweb Softech" />
        <meta name="description" content="Digiweb Softech students are consistently attaining remarkable achievements in highly coveted national and international examinations | Digiweb Softech
"/>
        <meta name="keywords" content="Digiweb Softech offers Live, Online Math, English, Science, Coding, SAT, ACT, AP, SSAT, STAAR, and AMC classes for students in grades 2-12. The programs of Digiweb Softech are specifically designed to accelerate your child's academic progress, programs, GUARANTEED.| Digiweb Softech
"/>
        <meta name="robots" content="index, follow" />
        <title>Success stories of Digiweb Softech Students & happy Parents Feedback | Digiweb Softech</title>
      </Helmet>
      <div className="comment-box">
        <div className="comment-header">
          <span className="commenter-name">{name}</span>
          <div className="star-rating">{stars(stars_arr)}</div>
        </div>
        <div className="comment-content">
          <p>{comment}</p>
        </div>
      </div>
    </>
  );
};
const StudentJob = () => {
  const [firstSection, setFirstSection]=useState(true);
  const [secondSection, setSecondSection]=useState(false);
  const [thirdSection, setThirdSection]=useState(false);
  AOS.init({
    offset: 200,
    duration: 600,
  });
  const handleSelection=(param)=>{
    switch(param){
      case "1":
        setFirstSection(true);
        setSecondSection(false);
        setThirdSection(false);
        break;
      case "2":
        setFirstSection(false);
        setSecondSection(true);
        setThirdSection(false);
        break;
      case "3":
        setFirstSection(false);
        setSecondSection(false);
        setThirdSection(true);
        break;
    }
  }
  const renderTemplate=()=>{
    if(firstSection){
      return (  <div class=" " id="" role="tabpanel" aria-labelledby="pills-one-tab" tabindex="0">
      <div class="row justify-content-between align-items-center">
          <div class="col-lg-7 aos-init aos-animate" data-aos="zoom-in-up">
              <h2 class="fw-semibold mb-4 title-font">Our Mission</h2>
<p>DigiWeb Softech: Empowering Businesses through Digital Excellence
At DigiWeb Softech, our mission is to empower businesses of all sizes with the digital marketing solutions they need to thrive in the ever-evolving digital landscape. We believe that every business, regardless of its industry or scale, deserves a strong online presence and the ability to connect effectively with its target audience.</p>

<p>Our Guiding Principles:</p>

<p>Strategic Growth: We craft customized digital marketing strategies that align with your unique business goals and objectives. We delve deep into understanding your business, your target audience, and your competitive landscape to develop strategies that maximize your online visibility, generate high-quality leads, and drive conversions.

Creative Innovation: We embrace innovation and creativity, constantly seeking new and engaging ways to connect your brand with your customers. Through data-driven insights, compelling content, and captivating design, we create impactful digital experiences that leave a lasting impression.

Long-Term Partnership: We believe in fostering long-lasting partnerships with our clients, working hand-in-hand to achieve your digital marketing objectives. We prioritize transparent communication, ensuring that your voice is heard and your vision is brought to life. Our commitment to exceptional customer service extends throughout every stage of our journey together.</p>

<p>DigiWeb Softech: Your Trusted Partner in Digital Success

Partner with DigiWeb Softech and unleash the power of digital marketing to propel your business to new heights. We are committed to providing you with the expertise, creativity, and strategic guidance you need to thrive in the dynamic digital world.</p>
            
          </div>
          <div class="col-lg-5 pt-4 aos-init aos-animate img-responsive" data-aos="zoom-in-up">
              <Image src={mission} class="rounded-img" responsive/>
          </div>
      </div>
      
  </div>
 )
    }else if(secondSection){
      return(
        <div class="" id="pills-two" role="tabpanel" aria-labelledby="pills-two-tab" tabindex="0">
      <div class="row justify-content-between align-items-center">
          <div class="col-lg-7 aos-init aos-animate" data-aos="fade-up-right">
              <h2 class="fw-semibold mb-4 title-font">Our Vision</h2>
              


<p>At DigiWeb Softech, we envision a world where businesses of all sizes can flourish in the ever-evolving digital realm. We harness the transformative power of innovative and data-driven marketing strategies to propel our clients towards tangible success.

At DigiWeb Softech, we are committed to continuous learning and improvement. We embrace the challenges posed by the dynamic digital landscape, constantly seeking new frontiers of innovation and delivering cutting-edge solutions that stay ahead of the curve. Our agile and adaptable approach ensures that our clients maintain a competitive edge.</p>

<p>Our clients' success is our paramount priority. We align ourselves with your business objectives, driving sustainable results that help you outshine the competition.</p>
          </div>
          <div class="col-lg-5 pt-4 aos-init aos-animate" data-aos="fade-up-left">
              <Image src={vission} class="rounded-img" responsive/>
          </div>
      </div>
      
  </div>
      )
      
 
    }
    else {
      return ( <div class="" id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab" tabindex="0">
      <div class="row justify-content-between align-items-center">
          <div class="col-lg-7 aos-init aos-animate" data-aos="fade-up-right">

              <h2 class="fw-semibold mb-4 title-font">Our Values</h2>
              
              <p>At DigiWeb Softech, we are guided by a set of unwavering values that define our approach to digital marketing and shape our company culture. These values reflect our unwavering commitment to delivering exceptional services and achieving extraordinary results for our clients.</p>

Three core values that underpin our success are:

<p>Client-Centricity: Our unwavering focus is on our clients' success. We delve deeply into their goals, understand their challenges, and craft customized digital marketing strategies that drive tangible results. Client satisfaction is the cornerstone of everything we do.

Excellence: We are passionate about achieving excellence in digital marketing. Our team of skilled professionals stays abreast of the latest industry trends, technologies, and best practices to deliver high-quality work that consistently exceeds expectations. We are committed to continuous improvement in all aspects of our services.

Integrity: DigiWeb Softech upholds the highest standards of integrity, transparency, and honesty in all our interactions. We maintain ethical standards throughout our client relationships, ensuring that our clients can trust us with their brands and sensitive information. We are committed to building long-term, mutually beneficial partnerships based on trust and respect.</p>
          </div>
          <div class="col-lg-5 pt-4 aos-init aos-animate" data-aos="fade-up-left">
              <Image src={values} class="rounded-img" responsive/>
          </div>
      </div>
  </div>)
    }
  }
  return (
    <div>
      <Navbar />
      <section class="py-5 shape1bg">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-lg-12">
                    <div class="cominfo">
                        <ul class="nav nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button  className={`nav-link ${firstSection ? 'active-link':''}`}  id="pills-one-tab" data-bs-toggle="pill" data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one" aria-selected="true" onClick={()=>handleSelection("1")}>Our Mission</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button className={`nav-link ${secondSection ? 'active-link':''}`} id="pills-two-tab" data-bs-toggle="pill" data-bs-target="#pills-two" type="button" role="tab" aria-controls="pills-two" aria-selected="false" tabindex="-1" onClick={()=>handleSelection("2")}>Our Vision</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button className={`nav-link ${thirdSection ? 'active-link':''}`} id="pills-three-tab" data-bs-toggle="pill" data-bs-target="#pills-three" type="button" role="tab" aria-controls="pills-three" aria-selected="false" tabindex="-1" onClick={()=>handleSelection("3")}>Our Values</button>
                            </li>
                        </ul>
                        {console.log(firstSection)}
                        <div class="tab-content pt-3" id="pills-tabContent">
                           {renderTemplate()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
      <Footer />
    </div>
  );
};
export default StudentJob;
